/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * coffee-paste-backend
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This file is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the file manually.
 */

import * as url from "url";
const isomorphicFetch = fetch;
import { Configuration } from "./configuration";

// Auto-generated by 'generate-api.js'
import { envFacade } from '../../env-facade';
const BASE_PATH = envFacade.apiUrl;

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = isomorphicFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum CollectionOperators {
    InCollection = <any> 'inCollection',
    NotInCollection = <any> 'notInCollection'
}
/**
 * 
 * @export
 * @interface ContentNoteIdBody
 */
export interface ContentNoteIdBody {
    /**
     * 
     * @type {string}
     * @memberof ContentNoteIdBody
     */
    contentHTML: string;
    /**
     * 
     * @type {string}
     * @memberof ContentNoteIdBody
     */
    contentText: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum Encryption {
    NONE = <any> 'NONE',
    PASSWORD = <any> 'PASSWORD',
    CERTIFICATE = <any> 'CERTIFICATE'
}
/**
 * 
 * @export
 * @interface EncryptionNoteIdBody
 */
export interface EncryptionNoteIdBody {
    /**
     * 
     * @type {Encryption}
     * @memberof EncryptionNoteIdBody
     */
    encryption: Encryption;
    /**
     * 
     * @type {string}
     * @memberof EncryptionNoteIdBody
     */
    contentText: string;
    /**
     * 
     * @type {string}
     * @memberof EncryptionNoteIdBody
     */
    contentHTML: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum FetchPageOptions {
    All = <any> 'all',
    Backlog = <any> 'backlog',
    Workspace = <any> 'workspace'
}
/**
 * 
 * @export
 * @interface FilterOptions
 */
export interface FilterOptions {
    /**
     * 
     * @type {FilterOptionsMatch}
     * @memberof FilterOptions
     */
    match?: FilterOptionsMatch;
    /**
     * 
     * @type {FilterOptionsRange}
     * @memberof FilterOptions
     */
    range?: FilterOptionsRange;
    /**
     * 
     * @type {FilterOptionsRange}
     * @memberof FilterOptions
     */
    outRange?: FilterOptionsRange;
    /**
     * 
     * @type {FilterOptionsRelation}
     * @memberof FilterOptions
     */
    relation?: FilterOptionsRelation;
    /**
     * 
     * @type {FilterOptionsCollection}
     * @memberof FilterOptions
     */
    collection?: FilterOptionsCollection;
}
/**
 * 
 * @export
 * @interface FilterOptionsCollection
 */
export interface FilterOptionsCollection {
    /**
     * 
     * @type {CollectionOperators}
     * @memberof FilterOptionsCollection
     */
    collectionOperator: CollectionOperators;
    /**
     * 
     * @type {Array<string | number>}
     * @memberof FilterOptionsCollection
     */
    values: Array<string | number>;
}
/**
 * 
 * @export
 * @interface FilterOptionsMatch
 */
export interface FilterOptionsMatch {
    /**
     * 
     * @type {MatchOperators}
     * @memberof FilterOptionsMatch
     */
    matchOperator: MatchOperators;
    /**
     * 
     * @type {string}
     * @memberof FilterOptionsMatch
     */
    value: string;
}
/**
 * 
 * @export
 * @interface FilterOptionsRange
 */
export interface FilterOptionsRange {
    /**
     * 
     * @type {number}
     * @memberof FilterOptionsRange
     */
    to: number;
    /**
     * 
     * @type {number}
     * @memberof FilterOptionsRange
     */
    from: number;
}
/**
 * 
 * @export
 * @interface FilterOptionsRelation
 */
export interface FilterOptionsRelation {
    /**
     * 
     * @type {RelationOperators}
     * @memberof FilterOptionsRelation
     */
    relationOperator: RelationOperators;
    /**
     * 
     * @type {number}
     * @memberof FilterOptionsRelation
     */
    value: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum MatchOperators {
    StartWith = <any> 'startWith',
    Contains = <any> 'contains',
    NotContains = <any> 'notContains',
    EndWith = <any> 'endWith',
    Equals = <any> 'equals',
    NotEquals = <any> 'notEquals'
}
/**
 * 
 * @export
 * @interface NameNoteIdBody
 */
export interface NameNoteIdBody {
    /**
     * 
     * @type {string}
     * @memberof NameNoteIdBody
     */
    name: string;
}
/**
 * 
 * @export
 * @interface Note
 */
export interface Note {
    /**
     * Note unique id
     * @type {string}
     * @memberof Note
     */
    id: string;
    /**
     * The note owner
     * @type {string}
     * @memberof Note
     */
    userId: string;
    /**
     * The note name (optional)
     * @type {string}
     * @memberof Note
     */
    name?: string;
    /**
     * The note creation UTC time
     * @type {number}
     * @memberof Note
     */
    creationTime: number;
    /**
     * The note last update UTC time
     * @type {number}
     * @memberof Note
     */
    lastModifiedTime: number;
    /**
     * The note content as plain-text
     * @type {string}
     * @memberof Note
     */
    contentText: string;
    /**
     * The note content as HTML
     * @type {string}
     * @memberof Note
     */
    contentHTML: string;
    /**
     * 
     * @type {Encryption}
     * @memberof Note
     */
    encryption: Encryption;
    /**
     * The note password encryption version code-name
     * @type {string}
     * @memberof Note
     */
    passwordVersionCodeName?: string;
    /**
     * The note password encryption version code-name
     * @type {string}
     * @memberof Note
     */
    certificateVersionCodeName?: string;
    /**
     * This (unique read-only) random key used to salt the note encryption (if required) So the password/certificate alone will not be en enough to decrypted note content.
     * @type {string}
     * @memberof Note
     */
    randomNoteSalt: string;
    /**
     * The note tags
     * @type {Array<string>}
     * @memberof Note
     */
    tags: Array<string>;
}
/**
 * Note status
 * @export
 * @enum {string}
 */
export enum NoteStatus {
    WORKSPACE = <any> 'WORKSPACE',
    BACKLOG = <any> 'BACKLOG'
}
/**
 * 
 * @export
 * @interface NotesBody
 */
export interface NotesBody {
    /**
     * 
     * @type {string}
     * @memberof NotesBody
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface NotesPage
 */
export interface NotesPage {
    /**
     * Page notes
     * @type {Array<Note>}
     * @memberof NotesPage
     */
    notes: Array<Note>;
    /**
     * Total *available* notes
     * @type {number}
     * @memberof NotesPage
     */
    totalCount: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum OAuth2Service {
    GITHUB = <any> 'GITHUB',
    GOOGLE = <any> 'GOOGLE',
    GITLAB = <any> 'GITLAB'
}
/**
 * The OAuth2 session
 * @export
 * @interface OAuth2Session
 */
export interface OAuth2Session {
    /**
     * The session logon code, see https://docs.github.com/en/developers/apps/authorizing-oauth-apps & https://developers.google.com/identity/protocols/oauth2/web-server
     * @type {string}
     * @memberof OAuth2Session
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof OAuth2Session
     */
    redirectUri: string;
    /**
     * 
     * @type {OAuth2Service}
     * @memberof OAuth2Session
     */
    oauth2Service: OAuth2Service;
}
/**
 * 
 * @export
 * @interface PageRequest
 */
export interface PageRequest {
    /**
     * 
     * @type {PageRequestOrderBy}
     * @memberof PageRequest
     */
    orderBy?: PageRequestOrderBy;
    /**
     * 
     * @type {number}
     * @memberof PageRequest
     */
    fromIndex: number;
    /**
     * 
     * @type {number}
     * @memberof PageRequest
     */
    pageSize: number;
    /**
     * 
     * @type {PageRequestFilter}
     * @memberof PageRequest
     */
    filter?: PageRequestFilter;
}
/**
 * 
 * @export
 * @interface PageRequestFilter
 */
export interface PageRequestFilter {
    /**
     * 
     * @type {FilterOptions}
     * @memberof PageRequestFilter
     */
    name?: FilterOptions;
    /**
     * 
     * @type {FilterOptions}
     * @memberof PageRequestFilter
     */
    creationTime?: FilterOptions;
    /**
     * 
     * @type {FilterOptions}
     * @memberof PageRequestFilter
     */
    lastModifiedTime?: FilterOptions;
    /**
     * 
     * @type {FilterOptions}
     * @memberof PageRequestFilter
     */
    contentText?: FilterOptions;
}
/**
 * Order by note fields
 * @export
 * @interface PageRequestOrderBy
 */
export interface PageRequestOrderBy {
    /**
     * 
     * @type {string}
     * @memberof PageRequestOrderBy
     */
    name?: PageRequestOrderBy.NameEnum;
    /**
     * 
     * @type {string}
     * @memberof PageRequestOrderBy
     */
    creationTime?: PageRequestOrderBy.CreationTimeEnum;
    /**
     * 
     * @type {string}
     * @memberof PageRequestOrderBy
     */
    lastModifiedTime?: PageRequestOrderBy.LastModifiedTimeEnum;
    /**
     * 
     * @type {string}
     * @memberof PageRequestOrderBy
     */
    contentText?: PageRequestOrderBy.ContentTextEnum;
}

/**
 * @export
 * @namespace PageRequestOrderBy
 */
export namespace PageRequestOrderBy {
    /**
     * @export
     * @enum {string}
     */
    export enum NameEnum {
        ASC = <any> 'ASC',
        DESC = <any> 'DESC'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum CreationTimeEnum {
        ASC = <any> 'ASC',
        DESC = <any> 'DESC'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum LastModifiedTimeEnum {
        ASC = <any> 'ASC',
        DESC = <any> 'DESC'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum ContentTextEnum {
        ASC = <any> 'ASC',
        DESC = <any> 'DESC'
    }
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum RelationOperators {
    Equals = <any> 'equals',
    NotEquals = <any> 'notEquals',
    Less = <any> 'less',
    LessOrEquals = <any> 'lessOrEquals',
    Greater = <any> 'greater',
    GreaterOrEquals = <any> 'greaterOrEquals'
}
/**
 * 
 * @export
 * @interface StatusNoteIdBody
 */
export interface StatusNoteIdBody {
    /**
     * 
     * @type {NoteStatus}
     * @memberof StatusNoteIdBody
     */
    status: NoteStatus;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * User unique id
     * @type {string}
     * @memberof User
     */
    id: string;
    /**
     * The user logon provider unique id TODO: add index
     * @type {string}
     * @memberof User
     */
    uniqueOAuthId: string;
    /**
     * The user unique email (can be ) TODO: add index
     * @type {string}
     * @memberof User
     */
    email?: string;
    /**
     * The user display name
     * @type {string}
     * @memberof User
     */
    displayName?: string;
    /**
     * The user avatar as Base64 string
     * @type {string}
     * @memberof User
     */
    avatarBase64?: string;
    /**
     * The open notes (user workspace) collection
     * @type {Array<string>}
     * @memberof User
     */
    openNotes: Array<string>;
    /**
     * The user's tags collection
     * @type {Array<string>}
     * @memberof User
     */
    tags: Array<string>;
    /**
     * The local storage salt encryption key. This is *NOT* a user password, it's only used to help encrypt the user notes key stored in the local storage so only one that have access to the API will be able to read the local storage. The real encryption key will never ever will be sent to the server.
     * @type {string}
     * @memberof User
     */
    localStorageSalt?: string;
    /**
     * A key that used to read the key that stored on the local storage
     * @type {string}
     * @memberof User
     */
    localStorageKek?: string;
    /**
     * An numen readable code for password version, used to detect the encryption version of each note in case of key change
     * @type {string}
     * @memberof User
     */
    passwordVersionCodeName?: string;
    /**
     * An numen readable code for certificate version, used to detect the encryption version of each note in case of certificate change
     * @type {string}
     * @memberof User
     */
    certificateVersionCodeName?: string;
}
/**
 * AuthenticationApi - fetch parameter creator
 * @export
 */
export const AuthenticationApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Login to service *after* getting the authorization service OAuth2 code.
         * @param {OAuth2Session} body The OAuth logon session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authByOAuth(body: OAuth2Session, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling authByOAuth.');
            }
            const localVarPath = `/auth/oauth2`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
			localVarRequestOptions.credentials = 'include';
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"OAuth2Session" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(options: any = {}): FetchArgs {
            const localVarPath = `/auth/logout`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("authentication")
					: configuration.apiKey;
                localVarHeaderParameter["authentication"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
			localVarRequestOptions.credentials = 'include';
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationApi - functional programming interface
 * @export
 */
export const AuthenticationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Login to service *after* getting the authorization service OAuth2 code.
         * @param {OAuth2Session} body The OAuth logon session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authByOAuth(body: OAuth2Session, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AuthenticationApiFetchParamCreator(configuration).authByOAuth(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AuthenticationApiFetchParamCreator(configuration).logout(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AuthenticationApi - factory interface
 * @export
 */
export const AuthenticationApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * Login to service *after* getting the authorization service OAuth2 code.
         * @param {OAuth2Session} body The OAuth logon session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authByOAuth(body: OAuth2Session, options?: any) {
            return AuthenticationApiFp(configuration).authByOAuth(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(options?: any) {
            return AuthenticationApiFp(configuration).logout(options)(fetch, basePath);
        },
    };
};

/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
export class AuthenticationApi extends BaseAPI {
    /**
     * Login to service *after* getting the authorization service OAuth2 code.
     * @param {OAuth2Session} body The OAuth logon session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authByOAuth(body: OAuth2Session, options?: any) {
        return AuthenticationApiFp(this.configuration).authByOAuth(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public logout(options?: any) {
        return AuthenticationApiFp(this.configuration).logout(options)(this.fetch, this.basePath);
    }

}
/**
 * NotesApi - fetch parameter creator
 * @export
 */
export const NotesApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create new note in the workspace
         * @param {NotesBody} body 
         * @param {string} [channelSid] The front session channel, used to skip this channel while updating succeed action via WS
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNote(body: NotesBody, channelSid?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createNote.');
            }
            const localVarPath = `/notes`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("authentication")
					: configuration.apiKey;
                localVarHeaderParameter["authentication"] = localVarApiKeyValue;
            }

            if (channelSid !== undefined && channelSid !== null) {
                localVarHeaderParameter['channelSid'] = String(channelSid);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
			localVarRequestOptions.credentials = 'include';
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"NotesBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Permanently delete note
         * @param {string} noteId 
         * @param {string} [channelSid] The front session channel, used to skip this channel while updating succeed action via WS
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNotes(noteId: string, channelSid?: string, options: any = {}): FetchArgs {
            // verify required parameter 'noteId' is not null or undefined
            if (noteId === null || noteId === undefined) {
                throw new RequiredError('noteId','Required parameter noteId was null or undefined when calling deleteNotes.');
            }
            const localVarPath = `/notes/{noteId}`
                .replace(`{${"noteId"}}`, encodeURIComponent(String(noteId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("authentication")
					: configuration.apiKey;
                localVarHeaderParameter["authentication"] = localVarApiKeyValue;
            }

            if (channelSid !== undefined && channelSid !== null) {
                localVarHeaderParameter['channelSid'] = String(channelSid);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
			localVarRequestOptions.credentials = 'include';
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBacklogNotes(options: any = {}): FetchArgs {
            const localVarPath = `/notes/backlog`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("authentication")
					: configuration.apiKey;
                localVarHeaderParameter["authentication"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
			localVarRequestOptions.credentials = 'include';
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBacklogNotesByUser(userId: string, options: any = {}): FetchArgs {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling getBacklogNotesByUser.');
            }
            const localVarPath = `/notes/backlog/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("api_key")
					: configuration.apiKey;
                localVarHeaderParameter["api_key"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
			localVarRequestOptions.credentials = 'include';
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Generating channel session in order to allow open web-socket channel. The key should append to the WS URL as channelSession param, the channel key is valid for 1 minute only. Note to keep this session and send it in the REST request channelSid so the current channel will not send update about request sent from this client.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannelKey(options: any = {}): FetchArgs {
            const localVarPath = `/notes/channel-session`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("authentication")
					: configuration.apiKey;
                localVarHeaderParameter["authentication"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
			localVarRequestOptions.credentials = 'include';
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} noteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNote(noteId: string, options: any = {}): FetchArgs {
            // verify required parameter 'noteId' is not null or undefined
            if (noteId === null || noteId === undefined) {
                throw new RequiredError('noteId','Required parameter noteId was null or undefined when calling getNote.');
            }
            const localVarPath = `/notes/{noteId}`
                .replace(`{${"noteId"}}`, encodeURIComponent(String(noteId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("authentication")
					: configuration.apiKey;
                localVarHeaderParameter["authentication"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
			localVarRequestOptions.credentials = 'include';
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PageRequest} body 
         * @param {FetchPageOptions} [fetchPageNotes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotesPage(body: PageRequest, fetchPageNotes?: FetchPageOptions, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getNotesPage.');
            }
            const localVarPath = `/notes/page`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("authentication")
					: configuration.apiKey;
                localVarHeaderParameter["authentication"] = localVarApiKeyValue;
            }

            if (fetchPageNotes !== undefined) {
                localVarQueryParameter['fetchPageNotes'] = fetchPageNotes;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
			localVarRequestOptions.credentials = 'include';
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PageRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpenNotes(options: any = {}): FetchArgs {
            const localVarPath = `/notes/workspace`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("authentication")
					: configuration.apiKey;
                localVarHeaderParameter["authentication"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
			localVarRequestOptions.credentials = 'include';
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpenNotesByUser(userId: string, options: any = {}): FetchArgs {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling getOpenNotesByUser.');
            }
            const localVarPath = `/notes/workspace/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("api_key")
					: configuration.apiKey;
                localVarHeaderParameter["api_key"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
			localVarRequestOptions.credentials = 'include';
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Set note content, (you can use also the WS channel API for that)
         * @param {ContentNoteIdBody} body 
         * @param {string} noteId 
         * @param {string} [channelSid] The front session channel, used to skip this channel while updating succeed action via WS
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setNoteContent(body: ContentNoteIdBody, noteId: string, channelSid?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling setNoteContent.');
            }
            // verify required parameter 'noteId' is not null or undefined
            if (noteId === null || noteId === undefined) {
                throw new RequiredError('noteId','Required parameter noteId was null or undefined when calling setNoteContent.');
            }
            const localVarPath = `/notes/content/{noteId}`
                .replace(`{${"noteId"}}`, encodeURIComponent(String(noteId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("authentication")
					: configuration.apiKey;
                localVarHeaderParameter["authentication"] = localVarApiKeyValue;
            }

            if (channelSid !== undefined && channelSid !== null) {
                localVarHeaderParameter['channelSid'] = String(channelSid);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
			localVarRequestOptions.credentials = 'include';
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ContentNoteIdBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Set note encryption method (including the new encrypted content, to override current content)
         * @param {EncryptionNoteIdBody} body 
         * @param {string} noteId 
         * @param {string} [channelSid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setNoteEncryptionMethod(body: EncryptionNoteIdBody, noteId: string, channelSid?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling setNoteEncryptionMethod.');
            }
            // verify required parameter 'noteId' is not null or undefined
            if (noteId === null || noteId === undefined) {
                throw new RequiredError('noteId','Required parameter noteId was null or undefined when calling setNoteEncryptionMethod.');
            }
            const localVarPath = `/notes/encryption/{noteId}`
                .replace(`{${"noteId"}}`, encodeURIComponent(String(noteId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("authentication")
					: configuration.apiKey;
                localVarHeaderParameter["authentication"] = localVarApiKeyValue;
            }

            if (channelSid !== undefined && channelSid !== null) {
                localVarHeaderParameter['channelSid'] = String(channelSid);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
			localVarRequestOptions.credentials = 'include';
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"EncryptionNoteIdBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Set note name
         * @param {NameNoteIdBody} body 
         * @param {string} noteId 
         * @param {string} [channelSid] The front session channel, used to skip this channel while updating succeed action via WS
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setNoteName(body: NameNoteIdBody, noteId: string, channelSid?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling setNoteName.');
            }
            // verify required parameter 'noteId' is not null or undefined
            if (noteId === null || noteId === undefined) {
                throw new RequiredError('noteId','Required parameter noteId was null or undefined when calling setNoteName.');
            }
            const localVarPath = `/notes/name/{noteId}`
                .replace(`{${"noteId"}}`, encodeURIComponent(String(noteId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("authentication")
					: configuration.apiKey;
                localVarHeaderParameter["authentication"] = localVarApiKeyValue;
            }

            if (channelSid !== undefined && channelSid !== null) {
                localVarHeaderParameter['channelSid'] = String(channelSid);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
			localVarRequestOptions.credentials = 'include';
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"NameNoteIdBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Move note from/to workspace/archive
         * @param {StatusNoteIdBody} body 
         * @param {string} noteId 
         * @param {string} [channelSid] The front session channel, used to skip this channel while updating succeed action via WS
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setNoteStatus(body: StatusNoteIdBody, noteId: string, channelSid?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling setNoteStatus.');
            }
            // verify required parameter 'noteId' is not null or undefined
            if (noteId === null || noteId === undefined) {
                throw new RequiredError('noteId','Required parameter noteId was null or undefined when calling setNoteStatus.');
            }
            const localVarPath = `/notes/status/{noteId}`
                .replace(`{${"noteId"}}`, encodeURIComponent(String(noteId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("authentication")
					: configuration.apiKey;
                localVarHeaderParameter["authentication"] = localVarApiKeyValue;
            }

            if (channelSid !== undefined && channelSid !== null) {
                localVarHeaderParameter['channelSid'] = String(channelSid);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
			localVarRequestOptions.credentials = 'include';
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"StatusNoteIdBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Set note tags collection. New tags will be added to the user's tag collection. NOTE! this tags collection will *override* current tags collection, if exist.
         * @param {Array<string>} body 
         * @param {string} noteId 
         * @param {string} [channelSid] The front session channel, used to skip this channel while updating succeed action via WS
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setNoteTags(body: Array<string>, noteId: string, channelSid?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling setNoteTags.');
            }
            // verify required parameter 'noteId' is not null or undefined
            if (noteId === null || noteId === undefined) {
                throw new RequiredError('noteId','Required parameter noteId was null or undefined when calling setNoteTags.');
            }
            const localVarPath = `/notes/tags/{noteId}`
                .replace(`{${"noteId"}}`, encodeURIComponent(String(noteId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("authentication")
					: configuration.apiKey;
                localVarHeaderParameter["authentication"] = localVarApiKeyValue;
            }

            if (channelSid !== undefined && channelSid !== null) {
                localVarHeaderParameter['channelSid'] = String(channelSid);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
			localVarRequestOptions.credentials = 'include';
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Array&lt;string&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotesApi - functional programming interface
 * @export
 */
export const NotesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Create new note in the workspace
         * @param {NotesBody} body 
         * @param {string} [channelSid] The front session channel, used to skip this channel while updating succeed action via WS
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNote(body: NotesBody, channelSid?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = NotesApiFetchParamCreator(configuration).createNote(body, channelSid, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Permanently delete note
         * @param {string} noteId 
         * @param {string} [channelSid] The front session channel, used to skip this channel while updating succeed action via WS
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNotes(noteId: string, channelSid?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = NotesApiFetchParamCreator(configuration).deleteNotes(noteId, channelSid, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBacklogNotes(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Note>> {
            const localVarFetchArgs = NotesApiFetchParamCreator(configuration).getBacklogNotes(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBacklogNotesByUser(userId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Note>> {
            const localVarFetchArgs = NotesApiFetchParamCreator(configuration).getBacklogNotesByUser(userId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Generating channel session in order to allow open web-socket channel. The key should append to the WS URL as channelSession param, the channel key is valid for 1 minute only. Note to keep this session and send it in the REST request channelSid so the current channel will not send update about request sent from this client.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannelKey(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = NotesApiFetchParamCreator(configuration).getChannelKey(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} noteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNote(noteId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Note> {
            const localVarFetchArgs = NotesApiFetchParamCreator(configuration).getNote(noteId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {PageRequest} body 
         * @param {FetchPageOptions} [fetchPageNotes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotesPage(body: PageRequest, fetchPageNotes?: FetchPageOptions, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<NotesPage> {
            const localVarFetchArgs = NotesApiFetchParamCreator(configuration).getNotesPage(body, fetchPageNotes, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpenNotes(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Note>> {
            const localVarFetchArgs = NotesApiFetchParamCreator(configuration).getOpenNotes(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpenNotesByUser(userId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Note>> {
            const localVarFetchArgs = NotesApiFetchParamCreator(configuration).getOpenNotesByUser(userId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Set note content, (you can use also the WS channel API for that)
         * @param {ContentNoteIdBody} body 
         * @param {string} noteId 
         * @param {string} [channelSid] The front session channel, used to skip this channel while updating succeed action via WS
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setNoteContent(body: ContentNoteIdBody, noteId: string, channelSid?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = NotesApiFetchParamCreator(configuration).setNoteContent(body, noteId, channelSid, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Set note encryption method (including the new encrypted content, to override current content)
         * @param {EncryptionNoteIdBody} body 
         * @param {string} noteId 
         * @param {string} [channelSid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setNoteEncryptionMethod(body: EncryptionNoteIdBody, noteId: string, channelSid?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = NotesApiFetchParamCreator(configuration).setNoteEncryptionMethod(body, noteId, channelSid, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Set note name
         * @param {NameNoteIdBody} body 
         * @param {string} noteId 
         * @param {string} [channelSid] The front session channel, used to skip this channel while updating succeed action via WS
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setNoteName(body: NameNoteIdBody, noteId: string, channelSid?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = NotesApiFetchParamCreator(configuration).setNoteName(body, noteId, channelSid, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Move note from/to workspace/archive
         * @param {StatusNoteIdBody} body 
         * @param {string} noteId 
         * @param {string} [channelSid] The front session channel, used to skip this channel while updating succeed action via WS
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setNoteStatus(body: StatusNoteIdBody, noteId: string, channelSid?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = NotesApiFetchParamCreator(configuration).setNoteStatus(body, noteId, channelSid, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Set note tags collection. New tags will be added to the user's tag collection. NOTE! this tags collection will *override* current tags collection, if exist.
         * @param {Array<string>} body 
         * @param {string} noteId 
         * @param {string} [channelSid] The front session channel, used to skip this channel while updating succeed action via WS
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setNoteTags(body: Array<string>, noteId: string, channelSid?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = NotesApiFetchParamCreator(configuration).setNoteTags(body, noteId, channelSid, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * NotesApi - factory interface
 * @export
 */
export const NotesApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * Create new note in the workspace
         * @param {NotesBody} body 
         * @param {string} [channelSid] The front session channel, used to skip this channel while updating succeed action via WS
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNote(body: NotesBody, channelSid?: string, options?: any) {
            return NotesApiFp(configuration).createNote(body, channelSid, options)(fetch, basePath);
        },
        /**
         * Permanently delete note
         * @param {string} noteId 
         * @param {string} [channelSid] The front session channel, used to skip this channel while updating succeed action via WS
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNotes(noteId: string, channelSid?: string, options?: any) {
            return NotesApiFp(configuration).deleteNotes(noteId, channelSid, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBacklogNotes(options?: any) {
            return NotesApiFp(configuration).getBacklogNotes(options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBacklogNotesByUser(userId: string, options?: any) {
            return NotesApiFp(configuration).getBacklogNotesByUser(userId, options)(fetch, basePath);
        },
        /**
         * Generating channel session in order to allow open web-socket channel. The key should append to the WS URL as channelSession param, the channel key is valid for 1 minute only. Note to keep this session and send it in the REST request channelSid so the current channel will not send update about request sent from this client.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannelKey(options?: any) {
            return NotesApiFp(configuration).getChannelKey(options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} noteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNote(noteId: string, options?: any) {
            return NotesApiFp(configuration).getNote(noteId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {PageRequest} body 
         * @param {FetchPageOptions} [fetchPageNotes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotesPage(body: PageRequest, fetchPageNotes?: FetchPageOptions, options?: any) {
            return NotesApiFp(configuration).getNotesPage(body, fetchPageNotes, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpenNotes(options?: any) {
            return NotesApiFp(configuration).getOpenNotes(options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpenNotesByUser(userId: string, options?: any) {
            return NotesApiFp(configuration).getOpenNotesByUser(userId, options)(fetch, basePath);
        },
        /**
         * Set note content, (you can use also the WS channel API for that)
         * @param {ContentNoteIdBody} body 
         * @param {string} noteId 
         * @param {string} [channelSid] The front session channel, used to skip this channel while updating succeed action via WS
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setNoteContent(body: ContentNoteIdBody, noteId: string, channelSid?: string, options?: any) {
            return NotesApiFp(configuration).setNoteContent(body, noteId, channelSid, options)(fetch, basePath);
        },
        /**
         * Set note encryption method (including the new encrypted content, to override current content)
         * @param {EncryptionNoteIdBody} body 
         * @param {string} noteId 
         * @param {string} [channelSid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setNoteEncryptionMethod(body: EncryptionNoteIdBody, noteId: string, channelSid?: string, options?: any) {
            return NotesApiFp(configuration).setNoteEncryptionMethod(body, noteId, channelSid, options)(fetch, basePath);
        },
        /**
         * Set note name
         * @param {NameNoteIdBody} body 
         * @param {string} noteId 
         * @param {string} [channelSid] The front session channel, used to skip this channel while updating succeed action via WS
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setNoteName(body: NameNoteIdBody, noteId: string, channelSid?: string, options?: any) {
            return NotesApiFp(configuration).setNoteName(body, noteId, channelSid, options)(fetch, basePath);
        },
        /**
         * Move note from/to workspace/archive
         * @param {StatusNoteIdBody} body 
         * @param {string} noteId 
         * @param {string} [channelSid] The front session channel, used to skip this channel while updating succeed action via WS
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setNoteStatus(body: StatusNoteIdBody, noteId: string, channelSid?: string, options?: any) {
            return NotesApiFp(configuration).setNoteStatus(body, noteId, channelSid, options)(fetch, basePath);
        },
        /**
         * Set note tags collection. New tags will be added to the user's tag collection. NOTE! this tags collection will *override* current tags collection, if exist.
         * @param {Array<string>} body 
         * @param {string} noteId 
         * @param {string} [channelSid] The front session channel, used to skip this channel while updating succeed action via WS
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setNoteTags(body: Array<string>, noteId: string, channelSid?: string, options?: any) {
            return NotesApiFp(configuration).setNoteTags(body, noteId, channelSid, options)(fetch, basePath);
        },
    };
};

/**
 * NotesApi - object-oriented interface
 * @export
 * @class NotesApi
 * @extends {BaseAPI}
 */
export class NotesApi extends BaseAPI {
    /**
     * Create new note in the workspace
     * @param {NotesBody} body 
     * @param {string} [channelSid] The front session channel, used to skip this channel while updating succeed action via WS
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotesApi
     */
    public createNote(body: NotesBody, channelSid?: string, options?: any) {
        return NotesApiFp(this.configuration).createNote(body, channelSid, options)(this.fetch, this.basePath);
    }

    /**
     * Permanently delete note
     * @param {string} noteId 
     * @param {string} [channelSid] The front session channel, used to skip this channel while updating succeed action via WS
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotesApi
     */
    public deleteNotes(noteId: string, channelSid?: string, options?: any) {
        return NotesApiFp(this.configuration).deleteNotes(noteId, channelSid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotesApi
     */
    public getBacklogNotes(options?: any) {
        return NotesApiFp(this.configuration).getBacklogNotes(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotesApi
     */
    public getBacklogNotesByUser(userId: string, options?: any) {
        return NotesApiFp(this.configuration).getBacklogNotesByUser(userId, options)(this.fetch, this.basePath);
    }

    /**
     * Generating channel session in order to allow open web-socket channel. The key should append to the WS URL as channelSession param, the channel key is valid for 1 minute only. Note to keep this session and send it in the REST request channelSid so the current channel will not send update about request sent from this client.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotesApi
     */
    public getChannelKey(options?: any) {
        return NotesApiFp(this.configuration).getChannelKey(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} noteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotesApi
     */
    public getNote(noteId: string, options?: any) {
        return NotesApiFp(this.configuration).getNote(noteId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {PageRequest} body 
     * @param {FetchPageOptions} [fetchPageNotes] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotesApi
     */
    public getNotesPage(body: PageRequest, fetchPageNotes?: FetchPageOptions, options?: any) {
        return NotesApiFp(this.configuration).getNotesPage(body, fetchPageNotes, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotesApi
     */
    public getOpenNotes(options?: any) {
        return NotesApiFp(this.configuration).getOpenNotes(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotesApi
     */
    public getOpenNotesByUser(userId: string, options?: any) {
        return NotesApiFp(this.configuration).getOpenNotesByUser(userId, options)(this.fetch, this.basePath);
    }

    /**
     * Set note content, (you can use also the WS channel API for that)
     * @param {ContentNoteIdBody} body 
     * @param {string} noteId 
     * @param {string} [channelSid] The front session channel, used to skip this channel while updating succeed action via WS
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotesApi
     */
    public setNoteContent(body: ContentNoteIdBody, noteId: string, channelSid?: string, options?: any) {
        return NotesApiFp(this.configuration).setNoteContent(body, noteId, channelSid, options)(this.fetch, this.basePath);
    }

    /**
     * Set note encryption method (including the new encrypted content, to override current content)
     * @param {EncryptionNoteIdBody} body 
     * @param {string} noteId 
     * @param {string} [channelSid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotesApi
     */
    public setNoteEncryptionMethod(body: EncryptionNoteIdBody, noteId: string, channelSid?: string, options?: any) {
        return NotesApiFp(this.configuration).setNoteEncryptionMethod(body, noteId, channelSid, options)(this.fetch, this.basePath);
    }

    /**
     * Set note name
     * @param {NameNoteIdBody} body 
     * @param {string} noteId 
     * @param {string} [channelSid] The front session channel, used to skip this channel while updating succeed action via WS
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotesApi
     */
    public setNoteName(body: NameNoteIdBody, noteId: string, channelSid?: string, options?: any) {
        return NotesApiFp(this.configuration).setNoteName(body, noteId, channelSid, options)(this.fetch, this.basePath);
    }

    /**
     * Move note from/to workspace/archive
     * @param {StatusNoteIdBody} body 
     * @param {string} noteId 
     * @param {string} [channelSid] The front session channel, used to skip this channel while updating succeed action via WS
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotesApi
     */
    public setNoteStatus(body: StatusNoteIdBody, noteId: string, channelSid?: string, options?: any) {
        return NotesApiFp(this.configuration).setNoteStatus(body, noteId, channelSid, options)(this.fetch, this.basePath);
    }

    /**
     * Set note tags collection. New tags will be added to the user's tag collection. NOTE! this tags collection will *override* current tags collection, if exist.
     * @param {Array<string>} body 
     * @param {string} noteId 
     * @param {string} [channelSid] The front session channel, used to skip this channel while updating succeed action via WS
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotesApi
     */
    public setNoteTags(body: Array<string>, noteId: string, channelSid?: string, options?: any) {
        return NotesApiFp(this.configuration).setNoteTags(body, noteId, channelSid, options)(this.fetch, this.basePath);
    }

}
/**
 * UsersApi - fetch parameter creator
 * @export
 */
export const UsersApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(options: any = {}): FetchArgs {
            const localVarPath = `/users`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("authentication")
					: configuration.apiKey;
                localVarHeaderParameter["authentication"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
			localVarRequestOptions.credentials = 'include';
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserByAdmin(userId: string, options: any = {}): FetchArgs {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling deleteUserByAdmin.');
            }
            const localVarPath = `/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("api_key")
					: configuration.apiKey;
                localVarHeaderParameter["api_key"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
			localVarRequestOptions.credentials = 'include';
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Remove tag from user collection (the tag will be removed from all user notes too)
         * @param {string} tag The tag to remove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserTag(tag: string, options: any = {}): FetchArgs {
            // verify required parameter 'tag' is not null or undefined
            if (tag === null || tag === undefined) {
                throw new RequiredError('tag','Required parameter tag was null or undefined when calling deleteUserTag.');
            }
            const localVarPath = `/users/tag`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("authentication")
					: configuration.apiKey;
                localVarHeaderParameter["authentication"] = localVarApiKeyValue;
            }

            if (tag !== undefined) {
                localVarQueryParameter['tag'] = tag;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
			localVarRequestOptions.credentials = 'include';
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(userId: string, options: any = {}): FetchArgs {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling getUser.');
            }
            const localVarPath = `/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("api_key")
					: configuration.apiKey;
                localVarHeaderParameter["api_key"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
			localVarRequestOptions.credentials = 'include';
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the key that used to read the key that stored on the local storage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserLocalStorageKeyEncryptionKey(options: any = {}): FetchArgs {
            const localVarPath = `/users/local-storage-kek`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("authentication")
					: configuration.apiKey;
                localVarHeaderParameter["authentication"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
			localVarRequestOptions.credentials = 'include';
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The local storage salt encryption key. This is *NOT* a password, it's only used to salt encrypt the user notes key stored in the local storage so only who that have access to the API will be able to read the local storage. The real encryption key will never ever will be sent to the server.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserLocalStorageSalt(options: any = {}): FetchArgs {
            const localVarPath = `/users/local-storage-salt`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("authentication")
					: configuration.apiKey;
                localVarHeaderParameter["authentication"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
			localVarRequestOptions.credentials = 'include';
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserProfile(options: any = {}): FetchArgs {
            const localVarPath = `/users/profile`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("authentication")
					: configuration.apiKey;
                localVarHeaderParameter["authentication"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
			localVarRequestOptions.credentials = 'include';
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers(options: any = {}): FetchArgs {
            const localVarPath = `/users`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("api_key")
					: configuration.apiKey;
                localVarHeaderParameter["api_key"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
			localVarRequestOptions.credentials = 'include';
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Increase the certificate-version-codename. Call it when you decided to change your local certificate, and you want to mark all note that encrypted with the old certificate that their encryption is by the old version of the certificate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        increaseUseCertificateVersionCodeName(options: any = {}): FetchArgs {
            const localVarPath = `/users/new-certificate-version-codename`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("authentication")
					: configuration.apiKey;
                localVarHeaderParameter["authentication"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
			localVarRequestOptions.credentials = 'include';
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Increase the password-version-codename. Call it when you decided to change your local password, and you want to mark all note that encrypted with the old password that their encryption is by the old version of the password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        increaseUserPasswordVersionCodeName(options: any = {}): FetchArgs {
            const localVarPath = `/users/new-password-version-codename`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("authentication")
					: configuration.apiKey;
                localVarHeaderParameter["authentication"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
			localVarRequestOptions.credentials = 'include';
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Regenerate and get a new key for encrypt and decrypt keys in the local storage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regenerateUserLocalStorageKeyEncryptionKey(options: any = {}): FetchArgs {
            const localVarPath = `/users/local-storage-kek/regenerate`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("authentication")
					: configuration.apiKey;
                localVarHeaderParameter["authentication"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
			localVarRequestOptions.credentials = 'include';
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Regenerate the user local storage salt encryption key, the actual meaning is that the user will have to re-type his password again in the browsers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regenerateUserLocalStorageSalt(options: any = {}): FetchArgs {
            const localVarPath = `/users/local-storage-salt/regenerate`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("authentication")
					: configuration.apiKey;
                localVarHeaderParameter["authentication"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
			localVarRequestOptions.credentials = 'include';
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).deleteUser(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserByAdmin(userId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).deleteUserByAdmin(userId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Remove tag from user collection (the tag will be removed from all user notes too)
         * @param {string} tag The tag to remove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserTag(tag: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).deleteUserTag(tag, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(userId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<User> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).getUser(userId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Get the key that used to read the key that stored on the local storage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserLocalStorageKeyEncryptionKey(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).getUserLocalStorageKeyEncryptionKey(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * The local storage salt encryption key. This is *NOT* a password, it's only used to salt encrypt the user notes key stored in the local storage so only who that have access to the API will be able to read the local storage. The real encryption key will never ever will be sent to the server.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserLocalStorageSalt(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).getUserLocalStorageSalt(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserProfile(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<User> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).getUserProfile(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<User>> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).getUsers(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Increase the certificate-version-codename. Call it when you decided to change your local certificate, and you want to mark all note that encrypted with the old certificate that their encryption is by the old version of the certificate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        increaseUseCertificateVersionCodeName(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).increaseUseCertificateVersionCodeName(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Increase the password-version-codename. Call it when you decided to change your local password, and you want to mark all note that encrypted with the old password that their encryption is by the old version of the password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        increaseUserPasswordVersionCodeName(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).increaseUserPasswordVersionCodeName(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Regenerate and get a new key for encrypt and decrypt keys in the local storage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regenerateUserLocalStorageKeyEncryptionKey(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).regenerateUserLocalStorageKeyEncryptionKey(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Regenerate the user local storage salt encryption key, the actual meaning is that the user will have to re-type his password again in the browsers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regenerateUserLocalStorageSalt(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).regenerateUserLocalStorageSalt(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(options?: any) {
            return UsersApiFp(configuration).deleteUser(options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserByAdmin(userId: string, options?: any) {
            return UsersApiFp(configuration).deleteUserByAdmin(userId, options)(fetch, basePath);
        },
        /**
         * Remove tag from user collection (the tag will be removed from all user notes too)
         * @param {string} tag The tag to remove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserTag(tag: string, options?: any) {
            return UsersApiFp(configuration).deleteUserTag(tag, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(userId: string, options?: any) {
            return UsersApiFp(configuration).getUser(userId, options)(fetch, basePath);
        },
        /**
         * Get the key that used to read the key that stored on the local storage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserLocalStorageKeyEncryptionKey(options?: any) {
            return UsersApiFp(configuration).getUserLocalStorageKeyEncryptionKey(options)(fetch, basePath);
        },
        /**
         * The local storage salt encryption key. This is *NOT* a password, it's only used to salt encrypt the user notes key stored in the local storage so only who that have access to the API will be able to read the local storage. The real encryption key will never ever will be sent to the server.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserLocalStorageSalt(options?: any) {
            return UsersApiFp(configuration).getUserLocalStorageSalt(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserProfile(options?: any) {
            return UsersApiFp(configuration).getUserProfile(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers(options?: any) {
            return UsersApiFp(configuration).getUsers(options)(fetch, basePath);
        },
        /**
         * Increase the certificate-version-codename. Call it when you decided to change your local certificate, and you want to mark all note that encrypted with the old certificate that their encryption is by the old version of the certificate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        increaseUseCertificateVersionCodeName(options?: any) {
            return UsersApiFp(configuration).increaseUseCertificateVersionCodeName(options)(fetch, basePath);
        },
        /**
         * Increase the password-version-codename. Call it when you decided to change your local password, and you want to mark all note that encrypted with the old password that their encryption is by the old version of the password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        increaseUserPasswordVersionCodeName(options?: any) {
            return UsersApiFp(configuration).increaseUserPasswordVersionCodeName(options)(fetch, basePath);
        },
        /**
         * Regenerate and get a new key for encrypt and decrypt keys in the local storage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regenerateUserLocalStorageKeyEncryptionKey(options?: any) {
            return UsersApiFp(configuration).regenerateUserLocalStorageKeyEncryptionKey(options)(fetch, basePath);
        },
        /**
         * Regenerate the user local storage salt encryption key, the actual meaning is that the user will have to re-type his password again in the browsers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regenerateUserLocalStorageSalt(options?: any) {
            return UsersApiFp(configuration).regenerateUserLocalStorageSalt(options)(fetch, basePath);
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public deleteUser(options?: any) {
        return UsersApiFp(this.configuration).deleteUser(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public deleteUserByAdmin(userId: string, options?: any) {
        return UsersApiFp(this.configuration).deleteUserByAdmin(userId, options)(this.fetch, this.basePath);
    }

    /**
     * Remove tag from user collection (the tag will be removed from all user notes too)
     * @param {string} tag The tag to remove
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public deleteUserTag(tag: string, options?: any) {
        return UsersApiFp(this.configuration).deleteUserTag(tag, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUser(userId: string, options?: any) {
        return UsersApiFp(this.configuration).getUser(userId, options)(this.fetch, this.basePath);
    }

    /**
     * Get the key that used to read the key that stored on the local storage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUserLocalStorageKeyEncryptionKey(options?: any) {
        return UsersApiFp(this.configuration).getUserLocalStorageKeyEncryptionKey(options)(this.fetch, this.basePath);
    }

    /**
     * The local storage salt encryption key. This is *NOT* a password, it's only used to salt encrypt the user notes key stored in the local storage so only who that have access to the API will be able to read the local storage. The real encryption key will never ever will be sent to the server.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUserLocalStorageSalt(options?: any) {
        return UsersApiFp(this.configuration).getUserLocalStorageSalt(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUserProfile(options?: any) {
        return UsersApiFp(this.configuration).getUserProfile(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUsers(options?: any) {
        return UsersApiFp(this.configuration).getUsers(options)(this.fetch, this.basePath);
    }

    /**
     * Increase the certificate-version-codename. Call it when you decided to change your local certificate, and you want to mark all note that encrypted with the old certificate that their encryption is by the old version of the certificate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public increaseUseCertificateVersionCodeName(options?: any) {
        return UsersApiFp(this.configuration).increaseUseCertificateVersionCodeName(options)(this.fetch, this.basePath);
    }

    /**
     * Increase the password-version-codename. Call it when you decided to change your local password, and you want to mark all note that encrypted with the old password that their encryption is by the old version of the password
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public increaseUserPasswordVersionCodeName(options?: any) {
        return UsersApiFp(this.configuration).increaseUserPasswordVersionCodeName(options)(this.fetch, this.basePath);
    }

    /**
     * Regenerate and get a new key for encrypt and decrypt keys in the local storage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public regenerateUserLocalStorageKeyEncryptionKey(options?: any) {
        return UsersApiFp(this.configuration).regenerateUserLocalStorageKeyEncryptionKey(options)(this.fetch, this.basePath);
    }

    /**
     * Regenerate the user local storage salt encryption key, the actual meaning is that the user will have to re-type his password again in the browsers.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public regenerateUserLocalStorageSalt(options?: any) {
        return UsersApiFp(this.configuration).regenerateUserLocalStorageSalt(options)(this.fetch, this.basePath);
    }

}
