
import { INoteChangedEventArgs } from "./tab-interfaces";
import { defineComponent } from "vue";

const NoteTabComponent = defineComponent({
  emits: {
    noteChanged: (e: INoteChangedEventArgs) => !!e.noteId,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: false,
    },
    lastNoteFeedUpdate: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      contentHTML: this.content,
      lastNoteInternalUpdate: `${new Date().getTime()}`,
    };
  },
  computed : {
      lastNoteUpdate(): string {
          return `${this.lastNoteInternalUpdate}:${this.lastNoteFeedUpdate}`;
      }
  },
  methods: {
    onChange(e: { htmlValue: string; textValue: string }): void {
      this.$emit("noteChanged", {
        noteId: this.id,
        contentHTML: e.htmlValue,
        contentText: e.textValue,
      });
    },
  },
});

export const NoteTab = NoteTabComponent;
export default NoteTab;
