
import { Options, Vue } from "vue-class-component";
import MainView from "@/components/main-view.vue"; // @ is an alias to /src

@Options({
  components: {
    MainView,
  },
})
export default class Home extends Vue {}
