
// This file was AutoGenerated at Sat Aug 27 2022 22:27:38 GMT+0300 (Israel Daylight Time) by 'generate-api.js'

import { createApiProxy } from '@/infrastructure/api-proxy-core';
import { credentialsManager } from '@/infrastructure/session-management/credential-manager';
import { AuthenticationApi, NotesApi, UsersApi } from '../api/api'

export class ApiFacade {
	public static get AuthenticationApi(): AuthenticationApi {
		return createApiProxy(new AuthenticationApi({ apiKey: credentialsManager.getToken() }));
	}

	public static get NotesApi(): NotesApi {
		return createApiProxy(new NotesApi({ apiKey: credentialsManager.getToken() }));
	}

	public static get UsersApi(): UsersApi {
		return createApiProxy(new UsersApi({ apiKey: credentialsManager.getToken() }));
	}

}
